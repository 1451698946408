import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Icon, Image } from 'semantic-ui-react';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash';

import SEO from 'src/components/SEO';
import SignUpForm from 'src/components/auth/SignUpForm';
import AuthPageWrap from 'src/components/auth/AuthPageWrap';

const SignupPage = ({
    location: {
        state: { email, redirectUrl },
    },
}) => {
    if (isEmpty(email)) {
        navigate('/continue');
        return null;
    }

    return (
        <AuthPageWrap>
            <SEO title="P.Volve Signup" />
            <Image
                src="https://cdn.shopify.com/s/files/1/2379/5349/files/pvolve-logo.jpg"
                size="small"
                centered
            />
            <Container textAlign="center">
                <p className="secondaryText margin-top--small">
                    Looks like you're new here.
                    <br />
                    Let's create your account.
                </p>
            </Container>
            <div className="margin-top--large">
                <SignUpForm email={email} redirectUrl={redirectUrl} />
            </div>
            <Container textAlign="center">
                <p className="margin-top--large">
                    <Link to="/continue">
                        <Icon name="arrow left" />
                        <b>Wait, I have an account. Go back.</b>
                    </Link>
                </p>
            </Container>
        </AuthPageWrap>
    );
};

SignupPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            email: PropTypes.string.isRequired,
            redirectUrl: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default SignupPage;
