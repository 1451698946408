import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { withFormik } from 'formik';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';

import Actions from 'src/state/root-actions';

import { redirect } from 'src/utils/url-utils';
import {
    FORM_MESSAGES,
    STRONG_PASSWORD_REGEX,
    getErrorMessage,
    getInfoMessage,
    hasAllValues,
} from 'src/utils/form-utils';

const SignUpForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () =>
        setShowPassword((showPasswordState) => !showPasswordState);

    // if no email, redirect to /continue
    useEffect(() => {
        if (isEmpty(values?.email)) {
            navigate('/continue');
        }
    }, [values]);

    return (
        <Form onSubmit={handleSubmit} size="large">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <h2 style={{ color: 'red' }}>{errors.general}</h2>
                        <Form.Field>
                            <Form.Input
                                label={{
                                    children: 'First Name',
                                    htmlFor: 'signup-first-name',
                                    className: values.firstName ? '' : 'hidden',
                                }}
                                id="signup-first-name"
                                name="firstName"
                                placeholder="First Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                error={
                                    errors.firstName && touched.firstName
                                        ? getErrorMessage(errors.firstName)
                                        : false
                                }
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Form.Input
                                id="signup-last-name"
                                name="lastName"
                                label={{
                                    children: 'Last Name',
                                    htmlFor: 'signup-last-name',
                                    className: values.lastName ? '' : 'hidden',
                                }}
                                placeholder="Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                error={
                                    errors.lastName && touched.lastName
                                        ? getErrorMessage(errors.lastName)
                                        : false
                                }
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Form.Input
                                action={
                                    <Button
                                        type="button"
                                        icon={`eye${
                                            showPassword ? ' slash outline' : ''
                                        }`}
                                        onClick={toggleShowPassword}
                                    />
                                }
                                label={{
                                    children: 'Create Password',
                                    htmlFor: 'signup-password',
                                    className: values.password ? '' : 'hidden',
                                }}
                                id="signup-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Create Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={
                                    errors.password && touched.password
                                        ? getErrorMessage(errors.password)
                                        : false
                                }
                            />
                            {!(errors.password && touched.password) &&
                                getInfoMessage(
                                    FORM_MESSAGES.passwordValidation,
                                    false
                                )}
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Checkbox
                            id="optIn"
                            name="optIn"
                            label="Keep me up to date with Pvolve news and exclusive offers"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.optIn}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                !hasAllValues(values) ||
                                !isEmpty(errors)
                            }
                            className="margin-top--small margin-bottom--medium"
                            primary
                            fluid
                        >
                            CREATE ACCOUNT
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setSubmitting, setFieldError }) => {
    const onFailure = (message) => setFieldError('general', message);
    const onSuccess = () => redirect(props.redirectUrl);
    const onFulfill = () => setSubmitting(false);

    props.dispatch(
        Actions.auth.signup.trigger({
            ...formValues,
            onFailure,
            onSuccess,
            onFulfill,
        })
    );
};

const validate = (values) => {
    const error = {};

    if (!values.firstName) {
        error.firstName = FORM_MESSAGES.requiredField;
    }

    if (!values.lastName) {
        error.lastName = FORM_MESSAGES.requiredField;
    }

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    } else if (!STRONG_PASSWORD_REGEX.test(values.password)) {
        error.password = FORM_MESSAGES.passwordValidation;
    }

    return error;
};

const FormikSignUpForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => ({
        email: props.email,
        firstName: '',
        lastName: '',
        password: '',
        optIn: true,
    }),
})(SignUpForm);

export default connect()(FormikSignUpForm);
